<template>
  <div class="px-2">
    <b-card class="mb-1">
      <div class="d-flex justify-content-between">
        <h2>Détails de l'établissement</h2>
        <ButtonArchiverInscription
          v-if="etsDetails && isAdminOrAgentMTCA"
          :etablissement-id="etsDetails._id"
          :admin-etablissement-email="etsDetails.emailPromoteur"
          redirect-route="etablissements-list"
        />
      </div>
      <!-- <b-link class="brand-logo">
          <AppLogo />
        </b-link> -->
    </b-card>
    <h1 v-if="!isFetchingEtsDetails && !etsDetails">
      Aucun établissement ne correspond à cet enregistrement
    </h1>
    <content-loader
      v-if="isFetchingEtsDetails"
      view-box="0 0 400 460"
      :speed="2"
      primary-color="#f3f3f3"
      secondary-color="#ecebeb"
    >
      <circle cx="29" cy="30" r="17" />
      <rect x="58" y="18" rx="2" ry="2" width="140" height="10" />
      <rect x="58" y="34" rx="2" ry="2" width="140" height="10" />
      <rect x="-7" y="60" rx="2" ry="2" width="408" height="132" />
    </content-loader>
    <b-card v-if="etsDetails" class="mb-0">
      <b-row v-if="$can('administrate', 'etablissement')">
        <b-col v-if="!isEtablissementUser" sm="12">
          <h4>Statut du traitement des informations</h4>

          <!-- {{etsDetails.isRejected}} == {{etsDetails.isVerified}} -->

          <!-- <v-select
            v-model="form.type_ets"
            type="text"
            :options="typesEts"
            label="nom"
            required="true"
            class="select-size-lg"
          /> -->
          <div class="d-flex justify-content-between w-100">
            <b-dropdown
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              split
              :text="currentStatus"
              :variant="currentColor"
              left
            >
              <b-dropdown-item @click="onEtsStatusUpdate('false')">
                Non traité
              </b-dropdown-item>
              <b-dropdown-item @click="onEtsStatusUpdate('en cours')">
                Traitement en cours
              </b-dropdown-item>
              <b-dropdown-item @click="onEtsStatusUpdate('true')">
                Validé
              </b-dropdown-item>
            </b-dropdown>

            <div >
              <b-button
              class="ml-auto p-2"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                @click="updateData()"
              >
                Enregistrer les modifications
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-modal.modal-sendMail
                variant="outline-primary"
              >
                Envoyer un mail
              </b-button>
            </div>
          </div>
          <div
            v-if="
              $can('validate', 'etablissement') &&
              $can('reject', 'etablissement')
            "
            class="d-flex gap-10 flex-wrap-align-items-center mt-2"
          >
            <ButtonRejectEtablissement
              v-if="!etsDetails.isVerified"
              :ets-details="etsDetails"
              @onEtsRejectionSuccess="onEtsRejectionSuccess"
            />
            <ButtonValidateEtablissement
              v-if="!etsDetails.isVerified"
              :input-datas="etsDetails"
              @onEtsValidationSuccess="onEtsValidationSuccess"
            />
          </div>
          <hr />
        </b-col>
        <b-col v-else sm="12" class="d-flex justify-content-end ">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="updateData()"
          >
            Enregistrer les modifications
            <b-spinner v-if="isLoadingUpdate" small />
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <!-- etablissement -->
        <b-col sm="6">
          <h3 class="my-2">Établissement</h3>
          <p v-if="$can('administrate', 'etablissement')">
            <strong>IFU : </strong>
            <b-form-input v-digits-only="{ length: 13 }" v-if="etsDetails.ifu" v-model="etsDetails.ifu" />
            <b-form-input v-digits-only="{ length: 13 }" v-else v-model="newIFU" />
          </p>
          <p v-else><strong>IFU : </strong> {{ etsDetails.ifu }}</p>

          <p v-if="$can('administrate', 'etablissement')">
            <strong>RCCM: </strong>
            <b-form-input v-if="etsDetails.rccm" v-model="etsDetails.rccm" />
            <b-form-input v-else v-model="newRCCM" />
          </p>
          <p v-else><strong>RCCM : </strong> {{ etsDetails.rccm }}</p>

          <p v-if="$can('administrate', 'etablissement')">
            <strong>Numéro Agrément : </strong>
            <b-form-input
              v-if="etsDetails.mumeroAgrement"
              v-model="etsDetails.mumeroAgrement"
            />
            <b-form-input v-else v-model="newNumAgrement" />
          </p>
          <p v-else>
            <strong>Numéro Agrément :</strong> {{ etsDetails.mumeroAgrement }}
          </p>

          <p v-if="$can('administrate', 'etablissement')">
            <strong>Nom : </strong>
            <b-form-input v-model="etsDetails.nomEtablissement" />
          </p>
          <p v-else>
            <strong>Nom : </strong> {{ etsDetails.nomEtablissement }}
          </p>

          <div v-if="etsDetails && etsDetails.typeEtablissement">
            <p v-if="$can('administrate', 'etablissement')">
              <strong>Type : </strong>
              <b-form-input v-model="etsDetails.typeEtablissement.nom" disabled/>
            </p>
            <p v-else>
              <strong>Type : </strong> {{ etsDetails.typeEtablissement.nom }}
            </p>
          </div>

          <p v-if="$can('administrate', 'etablissement')">
            <strong>Adresse : </strong>
            <b-form-input v-model="etsDetails.adresseEtablissement" />
          </p>
          <p v-else>
            <strong>Adresse : </strong> {{ etsDetails.adresseEtablissement }}
          </p>

          <p v-if="$can('administrate', 'etablissement')">
            <strong>Téléphone : </strong>
            <b-form-input
              v-model="etsDetails.telephoneEtablissement"
              v-phonenumber-field
            />
          </p>
          <p v-else>
            <strong>Téléphone : </strong>
            {{ etsDetails.telephoneEtablissement }}
          </p>

          <p v-if="$can('administrate', 'etablissement')">
            <strong>Email de l'etablissement : </strong>
            <b-form-input v-model="etsDetails.emailEtablissement"/>
          </p>
          <p v-else>
            <strong>Email de l'etablissement : </strong>
            {{ etsDetails.emailEtablissement }}
          </p>
          <p>
          </p>
          <p v-if="$can('administrate', 'etablissement')">
            <DepartementCommuneArrondissementVillageForm
              :initialValues="etsDecoupageTerritorial"
              :breakPoints="{ cols: {sm: '12' } }"
              @input="(event) => {etsDecoupageTerritorialNew = event}"
              :isBold="isBold"
            />
          </p>
          <div v-else>
            <strong>Département : </strong> {{ etsDetails.departement }}
            <p><strong>Commune : </strong> {{ etsDetails.commune }}</p>
            <p><strong>Arrondissement : </strong> {{ etsDetails.arrondissement }}</p>
            <p><strong>Arrondissement : </strong> {{ etsDetails.quartier }}</p>

          </div>
        </b-col>
        <!-- promoteur -->
        <b-col>
          <h3 class="my-2">Promoteur</h3>

          <p v-if="$can('administrate', 'etablissement')">
            <strong>Nom du promoteur : </strong>
            <b-form-input v-model="etsDetails.nomPromoteur" />
          </p>
          <p v-else>
            <strong>Nom du promoteur : </strong> {{ etsDetails.nomPromoteur }}
          </p>

          <p v-if="$can('administrate', 'etablissement')">
            <strong>Prénom(s) du promoteur : </strong>
            <b-form-input v-model="etsDetails.prenomsPromoteur" />
          </p>
          <p v-else>
            <strong>Prénom(s) du promoteur : </strong>
            {{ etsDetails.prenomsPromoteur }}
          </p>

          <p v-if="$can('administrate', 'etablissement')">
            <strong>Téléphone du promoteur : </strong>
            <b-form-input
              v-model="etsDetails.telephonePromoteur"
              v-phonenumber-field
            />
          </p>
          <p v-else>
            <strong>Téléphone du promoteur : </strong>
            {{ etsDetails.telephonePromoteur }}
          </p>

          <p v-if="$can('administrate', 'etablissement')">
            <strong>Email du promoteur : </strong>
            <b-form-input v-model="etsDetails.emailPromoteur" />
          </p>
          <p v-else>
            <strong>Email du promoteur : </strong>
            {{ etsDetails.emailPromoteur }}
          </p>

          <p v-if="$can('administrate', 'etablissement')">
            <strong>CIP/NPI du promoteur : </strong>
            <b-form-input v-model="etsDetails.npicniPromoteur" />
          </p>
          <p v-else>
            <strong>CIP/NPI du promoteur : </strong>
            {{ etsDetails.npicniPromoteur }}
          </p>


          <p v-if="$can('administrate', 'etablissement')">
          <strong>Nationalité du promoteur : </strong>
          <v-select
            v-model="etsDetails.nationalitePromoteur"
            type="text"
            :options="pays"
            label="name"
          />
          </p>
          <p v-else>
          <strong>Nationalité du promoteur : </strong>
          {{ etsDetails.nationalitePromoteur }}
          </p>

          <hr class="my-1" />

          <h4>Pièces jointes</h4>
          <DetailsPiecesJointes :files="etsDetails.fichiers" />
          <h4 class='mt-1'>Modifier les pièces jointes</h4>
          <!-- edit files -->
          <b-col md="12">
            <b-form-group
              v-for="(fileInput, index) in piecesJointesList"
              :key="index"
              :label-for="fileInput.label + index"
              :label="fileInput.label"
              class=""
            >
              <b-form-file
                v-model="piecesJointesList[index].value"
                placeholder="Veuillez choisir un fichier"
                drop-placeholder="Déposez ici..."
                accept="application/pdf"
                :state="
                  !isValidFile(piecesJointesList[index].value) ? false : null
                "
              />
              <div>
                <small
                  v-if="!isValidFile(piecesJointesList[index].value)"
                  class="text-danger"
                  >La taille de ce fichier dépasse 1Mo
                </small>
              </div>
            </b-form-group>
          </b-col>
        </b-col>
      </b-row>

      <hr />

      <b-row v-if="isAdminOrAgentMTCA">
        <b-col>
          <h4 class="my-2">Liste des agents</h4>
          <ListAgentsComponent :etablissement-id="etablissementId" />
        </b-col>
      </b-row>
    </b-card>

    <!-- modal send mail-->
    <b-modal
      id="modal-sendMail"
      cancel-variant="outline-secondary"
      ok-title="Envoyer"
      cancel-title="Fermer"
      centered
      title="Mail au promoteur d'établissement"
      @ok="readySendMail()"
    >
      <b-form>
        <b-form-group>
          <label for="email">Email:</label>
          <b-form-input
            v-model="newEmail.to"
            type="email"
            placeholder="Email du promoteur"
          />
        </b-form-group>
        <b-form-group>
          <label for="email">Sujet:</label>
          <b-form-input
            v-model="newEmail.subject"
            type="email"
            placeholder="Sujet du mail"
          />
        </b-form-group>
        <b-form-group>
          <label for="password">Message</label>
          <b-form-textarea
            v-model="newEmail.htmlText"
            placeholder="Contenu du mail"
            rows="3"
          />
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BLink,
  BCardTitle,
  BCardText,
  BForm,
  BButton,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BFormFile,
  BSpinner,
  BBadge,
  BDropdown,
  BDropdownItem,
  VBModal,
  BModal,
  BFormTextarea,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import { mapActions } from "vuex";
import vSelect from "vue-select";
import AppLogo from "@/components/AppLogo.vue";
import RecaptchaWidget from "@/components/RecaptchaWidget.vue";
import localstorageService from "@/services/localstorage/localstorage.service";
import { ContentLoader } from "vue-content-loader";
import DetailsPiecesJointes from "@/views/apps/utilisateurs/etablissements/components/DetailsPiecesJointes.vue";
import ButtonArchiverInscription from "@/views/apps/utilisateurs/etablissements/components/ButtonArchiverInscription.vue";

import utilsService from "@/services/utils/utils.service";
import etablissementsStroreModule from "@/store/etablissements";
import {
  registerStoreModule,
  unregisterStoreModule,
} from "@/helpers/vuex-utils";
import ListAgentsComponent from "./agents/components/ListAgentsComponent.vue";
import ButtonRejectEtablissement from "./ButtonRejectEtablissement.vue";
import ButtonValidateEtablissement from "./ButtonValidateEtablissement.vue";
import DepartementCommuneArrondissementVillageForm from "@/components/DepartementCommuneArrondissementVillageForm.vue";
import filesService from '@/services/http/files.service'
// store module and vuex utilities

export default {
  components: {
    // BSV
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BFormFile,
    // validations
    AppLogo,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    RecaptchaWidget,
    BSpinner,
    BRow,
    BCol,
    ListAgentsComponent,
    BBadge,
    BDropdown,
    BDropdownItem,
    BModal,
    BFormTextarea,
    DepartementCommuneArrondissementVillageForm,

    ToastificationContent,
    ButtonRejectEtablissement,
    ButtonValidateEtablissement,
    ContentLoader,
    DetailsPiecesJointes,
    ButtonArchiverInscription,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  setup() {
    const requiredStoreModules = [
      { path: "etablissements", module: etablissementsStroreModule },
    ];
    // Register module
    registerStoreModule(requiredStoreModules);

    const { isEtablissementUser, isAdminEtablissement, isAgentEtablissement } =
      utilsService.currentUserUtils();

    return {
      isAdminEtablissement,
      isAgentEtablissement,
      isEtablissementUser,
      requiredStoreModules,
    };
  },
  data() {
    return {
      isFetchingEtsDetails: false,
      isBold: true,
      etsDetails: null,
      etablissementId: null,
      triggerDepartementFormValidations: false,
      currentStatus: null,
      currentColor: null,
      etsDecoupageTerritorial: {
        departement: null,
        commune: null,
        arrondissement: null,
        qtier: null,
      },
      etsDecoupageTerritorialNew : null,
      
      piecesJointesList: [
        { label: "Agrément/Licence", value: null },
        { label: "Registre de commerce", value: null },
        { label: "Pièce d'identité du promoteur", value: null },
      ],

      etsDepartment: {
        departement: null,
        commune: null,
        arrondissement: null,
        qtier: null,
      },
      // optionsTreatment: [
      //   {
      //   },
      // ]
      newIFU: null,
      newRCCM: null,
      newNumAgrement: null,

      newEmail: {
        to: null,
        subject: "Corrections à effectuer",
        htmlText: null,
      },
      pays: [],
      isLoadingUpdate: false,
    };
  },
  computed: {
    isAdminOrAgentMTCA() {
      const { currentUserId, userRole } = utilsService.currentUserUtils();
      return userRole.code === "agent_mtca" || userRole.code === "super_admin";
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(val, old) {
        const { etablissementId } = val.params;
        this.etablissementId = etablissementId;
        this.loadEtabs(this.etablissementId);
        this.pays = utilsService.getListCountries();
      },
    },
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules);
  },

  mounted() {},
  methods: {
    ...mapActions("etablissements", {
      getEtablissement: "getEtablissement",
      createEtablissement: "createEtablissement",
      action_updateEtablissement: "updateEtablissement",
      action_updateFileEtablissement: "updateFileEtablissement",
      action_getListTypesEtablissement: "getListTypesEtablissement",
    }),
    ...mapActions("utils", {
      sendMail: "sendMail",
    }),
    // -----------------------------------------------------------------
    loadEtabs(etablissementId) {
      this.isFetchingEtsDetails = true;
      this.getEtablissement(etablissementId)
        .then((res) => {
          this.etsDetails = res.data.resource;
          this.newEmail.to = this.etsDetails.emailPromoteur;
          this.isFetchingEtsDetails = false;

          this.etsDecoupageTerritorial.departement = this.etsDetails.departement
          this.etsDecoupageTerritorial.commune  = this.etsDetails.commune
          this.etsDecoupageTerritorial.arrondissement = this.etsDetails.arrondissement
          this.etsDecoupageTerritorial.qtier = this.etsDetails.quartier
          console.log('this.etsDetails::: 📌', this.etsDetails);

          this.etsDetails.fichiers.forEach(async (file, file_index) => {
            if (file) {
              // download file
              await this.downlodFile(file, file_index)
            }
          });

          // for (let index = 0; index < this.piecesJointesList.length; index++) {
          //   const element = this.piecesJointesList[index];
          // }
          
          // console.log(this.etsDetails);

          if (this.etsDetails.isVerified == "false") {
            this.currentStatus = "Non traité";
            this.currentColor = "danger";
          }
          if (this.etsDetails.isVerified == "true") {
            this.currentStatus = "Validé";
            this.currentColor = "success";
          }
          if (this.etsDetails.isVerified == "en cours") {
            this.currentStatus = "Traitement en cours";
            this.currentColor = "warning";
          }
        })
        .catch((err) => {
          this.isFetchingEtsDetails = false;
        });
    },
    updateData() {
      this.isLoadingUpdate = true
      this.isValdatingEts = true;
      if (this.newIFU) this.etsDetails.ifu = this.newIFU;
      if (this.newRCCM) this.etsDetails.rccm = this.newRCCM;
      if (this.newNumAgrement) {
        this.etsDetails.mumeroAgrement = this.newNumAgrement;
      }
      if (this.etsDetails.typeEtablissement._id) {
        this.etsDetails.typeEtablissement =
          this.etsDetails.typeEtablissement._id;
      }
      const id = this.etsDetails._id;
      this.etsDetails.departement= this.etsDepartment.departement
      this.etsDetails.commune= this.etsDepartment.commune
      this.etsDetails.arrondissement= this.etsDepartment.arrondissement
      this.etsDetails.quartier= this.etsDepartment.qtier
      delete this.etsDetails._id;
      delete this.etsDetails.__v;
      delete this.etsDetails.createdAt;
      delete this.etsDetails.updatedAt;

      this.triggerDepartementFormValidations = true;
      // console.log('this.etsDetails::: 🟢', this.etsDetails);
      // console.log('this.etsDecoupageTerritorialNew::: 🟢', this.etsDecoupageTerritorialNew);

      const files = this.piecesJointesList.map((e) => e.value).filter((e) => !!e);
      // console.log('files::: ⭕️', files);
      const allFilesAreValid = files.map((file) => this.isValidFile(file));
      for (let index = 0; index < allFilesAreValid.length; index++) {
        const element = allFilesAreValid[index];
        if (element === false) {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title:
                "Vos fichiers ne sont pas au bon format. Veuillez renseigner des fichiers de 10Mo maximum, au format PDF",
              icon: "CheckIcon",
              variant: "danger",
            },
          });
          this.isLoading = false;
          return;
        }
      }

      const formData = new FormData();
      const objectdata = {
        rccm: this.etsDetails.rccm,
        ifu: this.etsDetails.ifu,
        mumeroAgrement: this.etsDetails.mumeroAgrement,
        nomEtablissement: this.etsDetails.nomEtablissement,
        
        // typeEtablissement: this.etsDetails.type_ets._id,
        // sousTypeEtablissement: getValueFromInput(
        //   this.etsDetails.sous_type_ets,
        //   "radio"
        // ),
        adresseEtablissement: this.etsDetails.adresse_ets,
        telephoneEtablissement: this.etsDetails.telephone_ets,
        emailEtablissement: this.etsDetails.emailEtablissement.toLowerCase(),
        
        departement: this.etsDecoupageTerritorialNew.departement,
        commune: this.etsDecoupageTerritorialNew.commune,
        arrondissement: this.etsDecoupageTerritorialNew.arrondissement,
        quartier: this.etsDecoupageTerritorialNew.qtier,
        
        nomPromoteur: this.etsDetails.nom_promoteur,
        prenomsPromoteur: this.etsDetails.prenoms_promoteur,
        telephonePromoteur: this.etsDetails.telephone_promoteur,
        emailPromoteur: this.etsDetails.emailPromoteur.toLowerCase(),
        npicniPromoteur: this.etsDetails.cpi_npi_promoteur,
        nationalitePromoteur: this.etsDetails.nationalitePromoteur,
      };

      const filenumber = files.length || 0;
      formData.append("objectdata", JSON.stringify(objectdata));
      formData.append("fileprop", "fichiers");
      formData.append("filenumber", `${filenumber}`);
      for (let index = 0; index < files.length; index++) {
        const file = files[index];
        formData.append(`filedata${index}`, file);
      }

      // for (let pair of formData.entries()) {
      //   console.log(`${pair[0]} 💊: ${pair[1]}`);
      // }

      // return
      this.action_updateFileEtablissement({
        id : this.etablissementId,
        data: formData,
      })
        .then((result) => {
          // console.log('result action_updateFileEtablissement ::: ', result);
          this.isLoadingUpdate = false
          this.loadEtabs(this.etablissementId);

          // this.$emit('onEtsValidationSuccess',result)
          this.triggerDepartementFormValidations = false;
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Donnéee mise à jour avec succès",
              icon: "CheckIcon",
              variant: "success",
            },
          });
        })
        .catch((err) => {
          this.isLoadingUpdate = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.message || "Une erreur est survenue",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
    readySendMail() {
      const formData = new FormData();
      formData.append("objectdata", JSON.stringify(this.newEmail));
      this.sendMail(formData)
        .then((res) => {
          if (res.status == 200) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Mail Envoyé",
                icon: "CheckIcon",
                variant: "success",
              },
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.message || "Une erreur est survenue",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
    onEtsStatusUpdate(status) {
      this.isValdatingEts = true;
      // this.etsDetails = { ...this.etsDetails, isVerified: status }
      this.action_updateEtablissement({
        id: this.etsDetails._id,
        data: { isVerified: status },
      })
        .then((result) => {
          this.loadEtabs(this.etablissementId);

          // this.etsDetails.isVerified = status
          // this.$emit('onEtsValidationSuccess',result)
          this.isValdatingEts = false;
          // this.$toast({
          //   component: ToastificationContent,
          //   position: 'top-right',
          //   props: {
          //     title: 'Mise à jour avec succès',
          //     icon: 'CheckIcon',
          //     variant: 'success',
          //   },
          // })
        })
        .catch((err) => {
          this.isValdatingEts = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.message || "Une erreur est survenue",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
    isValidFile(file) {
      if (file && file.size && file.size > 5000000) {
        return false;
      }
      return true;
    },
    onEtsValidationSuccess() {
      this.etsDetails = { ...this.etsDetails, isVerified: "true" };
    },
    onEtsRejectionSuccess(evt) {
      this.$router.push({ name: "etablissements-list" });
    },
    downlodFile(file, index) {
      filesService
        .getFile({ name: file })
        .then(response => {
          const blob = new Blob([response.data], {
            type: `${file.resource_type}/${file.format}`,
          })
          const fileName = this.extractOriginalName(file)
          const objectFile = new File([blob], fileName, { type: 'application/pdf' });
          this.piecesJointesList[index].value = objectFile
        })
        .catch(error => {
          console.error(error)
        })
    },
    getCloudinaryFileName(file) {
      return `${file.original_filename}.${file.format}`
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
